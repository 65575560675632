body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
    margin: 0;
}

.wrapInputDateRangePicker {
    > svg {
        width: 20px;
        top: 9px !important;
        margin-top: 0 !important;
    }

    .inputDateRangePicker {
        font-size: 1rem !important;
        height: 40px !important;
    }
}

.wrapDateRangePicker {
    .empty {
        background-color: transparent !important;
    }

    .arrow {
        svg {
            width: 8px !important;
            height: 14px !important;
            overflow: visible;
        }
    }

    .endDateActive {
        border-radius: 0 10px 10px 0 !important;
    }
}

.input__after{
    svg{
        width: 20px;
    }

}